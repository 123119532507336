import prop from 'ramda/src/prop'
import clearListProcessing from 'root/src/client/logic/api/actions/clearListProcessing'
import clearList from 'root/src/client/logic/api/actions/clearList'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'

export default ({
	setInput, moduleId,	value, query, dispatch,
}) => {
	const hrefTab = prop('tab', query)
	const { endpointId } = value
	dispatch(clearListProcessing(currentRouteObj()))
	dispatch(clearList())
	setInput(moduleId, ['embedded', moduleId, 'fieldData', 'tab'], value ?? hrefTab, endpointId)
}
