import React, { memo, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { tertiaryColor } from 'root/src/client/web/commonStyles'
import tabConnector from 'root/src/client/logic/tab/connectors/tabConnector'
import setTab from 'root/src/client/web/base/Tab/setTab'
import Select from 'root/src/client/web/base/Select'
import { comparePropsFn } from 'root/src/shared/util/stateCompare'

const styles = {
	withMarginRight: {
		marginRight: 45,
	},
	subHeader: {
		fontSize: 18,
		userSelect: 'none',
		cursor: 'pointer',
		paddingBottom: 10,
		'@media (max-width: 500px)': {
			fontSize: 17,
		},
		'@media (max-width: 400px)': {
			fontSize: 16,
		},
		'@media (max-width: 365px)': {
			fontSize: 14,
		},
		'@media (max-width: 315px)': {
			fontSize: 12,
		},
	},
	spaceBetweenTabs: {
		justifyContent: 'space-between',
	},
	activeTab: {
		borderBottom: '3.5px solid #7840b6',
		zIndex: 0,
		borderRadius: 2,
		marginBottom: 0,
		WebkitTransition: '1s all ease',
		transition: '1s all ease',
	},
	inActiveTab: {
		color: tertiaryColor,
		'&:hover': {
			color: 'white',
		},
	},
}

const MAX_ROW_ITEMS = 3

export const CustomTabs = memo(({
	classes,
	isMobileRes,
	getFixedTabs,
	selectedTab,
	moduleId,
	setInput,
	query,
	dispatch,
}) => {
	const [tabs, setTabs] = useState([])

	// Select doesn't handle objects as values (key error) so we need to pass tabId
	const selectNewTab = (tabId) => {
		const tab = tabs.find(({ id }) => tabId === id)
		setTab({
			value: tab,
			moduleId,
			setInput,
			query,
			dispatch,
		})
	}

	const activeTab = useMemo(() => tabs.find(({ id }) => selectedTab === id) ?? tabs[0],
		[selectedTab, tabs])

	useEffect(() => {
		const fetchTabs = async () => {
			const fetchedTabs = await getFixedTabs(moduleId)
			setTabs(fetchedTabs)
		}
		fetchTabs()
	}, [getFixedTabs])

	if (!tabs || tabs.length < 1) return null

	return isMobileRes && tabs.length > MAX_ROW_ITEMS
		? (
			<Select
				options={tabs.map(({ id, tabLabel }) => ({ value: id, label: tabLabel }))}
				value={{ label: activeTab.tabLabel }}
				onSelect={({ value: tabId }) => selectNewTab(tabId)}
			/>
		)
		: (
			<div className={classNames('flex layout-row', isMobileRes ? classes.spaceBetweenTabs : '')}>
				{tabs.map((tab, index) => (
					<div
						className={classNames(classes.subHeader,
							activeTab === tab ? classes.activeTab : classes.inActiveTab,
							!isMobileRes && index !== tabs.length - 1 && classes.withMarginRight)}
						onClick={() => selectNewTab(tab.id)}
						key={tab.tabLabel}
					>
						{tab.tabLabel}
					</div>
				))}
			</div>
		)
}, comparePropsFn(['classes', 'tabs', 'selectedTab', 'isMobileRes']))

export default memo(tabConnector(CustomTabs, styles, 'MuiCustomTabs'))
