import React, { memo } from 'react'

import Button from '@material-ui/core/Button'

import {
	primaryButton,
	noBackgroundButton,
	outlinedButton,
	tobiiButton,
} from 'root/src/client/web/componentTypes'

import { comparePropsFn } from 'root/src/shared/util/stateCompare'

import classNames from 'classnames'
import linkConnector from 'root/src/client/logic/app/connectors/linkConnector'
import Link from 'next/link'
import styles from './buttonStyle'

export const BaseLink = memo(({
	children, classes, className, isStyled, buttonType, disabled, disableRipple, linkHref,
}) => (
	<Link href={linkHref}>
		<Button
			className={classNames(
				classes.button,
				{ [classes.styledButton]: isStyled },
				({ [classes.primaryButton]: buttonType === primaryButton }),
				({ [classes.noBackgroundButton]: buttonType === noBackgroundButton }),
				({ [classes.outlinedButton]: buttonType === outlinedButton }),
				({ [classes.tobii]: buttonType === tobiiButton }),
				className,
			)}
			disabled={disabled}
			disableRipple={disableRipple}
		>
			{children}
		</Button>
	</Link>
))

export default linkConnector(
	memo(BaseLink, comparePropsFn([
		'routeId', 'routeParams', 'classes', 'className',
		'isStyled', 'buttonType', 'disabled', 'disableRipple',
	])), styles, 'MuiLinkButton',
)
