import { memo, useState, useEffect, Fragment, useRef } from 'react'
import classNames from 'classnames'
import { Divider } from '@material-ui/core'
import is from 'ramda/src/is'
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import bannerHeaderConnector from 'root/src/client/logic/header/connectors/bannerHeaderConnector'
import withModuleContext from 'root/src/client/util/withModuleContext'
import Link from 'root/src/client/web/base/Link'
import LinkButton from 'root/src/client/web/base/LinkButton'
// eslint-disable-next-line import/no-named-as-default
import CustomTabs from 'root/src/client/web/base/Tab/CustomTabs'
import MaxWidthContainer from 'root/src/client/web/base/MaxWidthContainer'
import { colorsNew, ltGtMediaQuery } from 'root/src/client/web/commonStyles'
import EmbeddedModule from 'root/src/client/web/embedded/EmbeddedModule'
import Header from 'root/src/client/web/typography/Header'
import SubTitle from 'root/src/client/web/typography/SubTitle'
import Title from 'root/src/client/web/typography/Title'
import { orNull, ternary } from 'root/src/shared/util/ramdaPlus'
import filterIcon from 'root/src/client/public/icons/ic-filter.svg'
import Typography, { typographyVariants } from 'root/src/client/web/typography/Typography'
import { useWindowSize } from 'root/src/client/v2/common/hooks/useWindowSize'

const styles = {
	root: {
		minWidth: 206,
		padding: [[46, 0, 6, 0]],
		width: '100%',
		maxWidth: '90vw',
		margin: [[0, 'auto', 30, 'auto']],
	},
	banner: {
		position: 'relative',
		height: 40,
		marginBottom: 10,
	},
	bannerBg: {
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
	textBox: {
		padding: '16px 0',
		borderRadius: 30,
		margin: '0 auto',
		maxWidth: 500,
		'@media (max-width: 750px)': {
			maxWidth: 360,
		},

		'@media (max-width: 600px)': {
			maxWidth: 345,
		},
	},
	newDare: {
		fontSize: 14,
		color: 'white',
	},
	tobiiLabel: {
		fontSize: 14,
		color: 'white',
	},
	heading: {
		marginBottom: 20,
		marginTop: 20,
		display: 'flex',
		justifyContent: 'center',
	},
	createLinkContainer: {
		width: 180,
		height: 42,
		alignSelf: 'flex-end',
	},
	tobiiBtnContainer: {
		width: 180,
		height: 42,
		alignSelf: 'flex-end',
		transform: 'translateY(-25px)',
	},
	navButton: {
		[ltGtMediaQuery]: {
			alignSelf: 'center',
			marginBottom: 30,
		},
	},
	textOverlay: {
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		textAlign: 'center',
		position: 'absolute',
		color: 'white',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	embeddedModuleAndLinkContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
	embeddedModuleAndLinkContainerNav: {
		display: 'flex',
		alignItems: 'flex-end',
		[ltGtMediaQuery]: {
			flexDirection: 'column',
			alignItems: 'center',
			marginTop: '-30px',
		},
	},
	sort: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},
	fullWidth: {
		width: '100%',
	},
	notifyWrapper: {
		position: 'absolute',
		border: '1px solid #6200ee',
		backgroundColor: '#ffeb3b',
		padding: 10,
		color: colorsNew.dark2,
		zIndex: 1,
		left: 0,
		right: 0,
	},
	notifyType: {
		fontWeight: 600,
		margin: 0,
		postition: 'relative',
	},
	notifyMessage: {
		fontSize: '0.83em',
		margin: 0,
		fontWeight: 400,
		'& > a': {
			color: colorsNew.dark2,
			fontWeight: 600,
			textDecoration: 'underline',
			cursor: 'pointer',
			'&:hover': {
			},
		},
	},
	clearIcon: {
		position: 'absolute',
		right: 3,
		cursor: 'pointer',
		top: 3,
		color: 'black',
	},

	iconWrapper: {
		width: 42,
		height: 42,
		backgroundColor: colorsNew.dark2,
		borderRadius: 4,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	iconContainer: {
		width: 24,
		height: 24,
	},
	icon: {
		width: '100%',
	},
	divider: {
		backgroundColor: colorsNew.dark2,
		marginBottom: 20,
		height: 2,
		marginTop: '-3px',
	},
	bannerBox: {
		width: '100%',
		listStyle: 'none',
		height: 200,
		margin: [[30, 0, 0]],
		padding: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	bannerBoxItem: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	bannerBoxImage: {
		fill: '#fff',
		width: 100,
		height: 100,
	},
	textCenter: {
		textAlign: 'center',
	},
	embedModuleContainer: {
		[ltGtMediaQuery]: {
			flexDirection: 'column',
		},
	},
}

const TitleComponent = ({
	classes,
	title,
	newStyle,
}) => (
	newStyle
		? (
			<Typography
				className={classes.title}
				variant={typographyVariants.heading1}
			>
				{title}
			</Typography>
		) : <Title>{title}</Title>
)

const SubLabelComponent = ({
	classes,
	label,
	newStyle,
}) => (
	newStyle
		? (
			<div className={classNames(classes.heading, classes.textCenter)}>
				<Typography
					fontSize={20}
					color={colorsNew.light4}
					variant={typographyVariants.heading4}
				>
					{label}
				</Typography>
			</div>
		) : (
			<div className={classNames('flex layout-row', classes.heading)}>
				<Header>{label}</Header>
			</div>
		)
)

export const BannerHeaderUnconnected = memo(({
	bannerImageText, bannerImageSubText,
	textWithBg, bannerSubText, bannerTabs, linkLabel, linkRouteId,
	createNewDareActive, embeddedContent, isMobileRes,
	filtersRoute, moduleId, isNav, projectsLoaded, giveawayLink,
	hideFilters, bannerBoxImages, classes, bannerNewStyle, oneRow = false,
	bannerImage,
}) => {
	const [width] = useWindowSize()
	const [isNotified, setNotified] = useState(false)
	// eslint-disable-next-line
	let [navMargin, setNavMargin] = useState('')
	const getNavMargin = () => {
		const { innerWidth } = window
		const margin = innerWidth > 1808 ? (innerWidth - 1680) / 2 - 64 : 0
		return margin
	}

	const sizeHandler = () => {
		const margin = getNavMargin()
		setNavMargin(margin)
	}

	const projectsRef = useRef()
	useEffect(() => {
		const margin = getNavMargin()
		setNavMargin(margin)
		window.addEventListener('resize', sizeHandler)
		return (() => {
			window.removeEventListener('resize', sizeHandler)
		})
	}, [])

	return (
		<>
			{orNull(bannerImage, <div
				className={classNames(classes.bannerBg)}
				style={{
					backgroundImage: `url(${bannerImage?.src})`,
					width,
					height: width / 3.70,
				}}
			/>)}
			<div ref={projectsRef} className={classNames(classes.root, 'layout-column')}>
				{orNull(bannerImageText,
					(
						<div
							className={classNames(classes.banner, 'layout-row')}
						>
							{orNull(isNotified,
								(
									<div className={classes.notifyWrapper}>
										<span onClick={() => setNotified(false)} className={classes.clearIcon}><FontAwesomeIcon icon={faTimesCircle} size="lg" /></span>
										<h5 className={classes.notifyType}>Warning!</h5>
										<p className={classes.notifyMessage}>Your payment did not go through, please add a new <Link to="/payment">payment method</Link></p>
									</div>
								))}
							<div className={classes.textOverlay}>
								<div className={classNames({ [classes.textBox]: textWithBg })}>
									<TitleComponent
										classes={classes}
										title={bannerImageText}
										newStyle={bannerNewStyle}
									/>
									<SubTitle noMaxWidth>{bannerImageSubText}</SubTitle>
								</div>
							</div>
						</div>
					))
				}
				{
					bannerBoxImages.length
						? (
							<ul className={classes.bannerBox}>
								{bannerBoxImages.map(({ image: Image, text }) => (
									<li key={text} className={classes.bannerBoxItem}>
										<Image className={classes.bannerBoxImage}	/>
										<p>{text}</p>
									</li>
								))}
							</ul>
						)
						: null
				}
				<div className="layout-row layout-align-center">
					<MaxWidthContainer>
						<div className={classNames(oneRow
							? 'flex layout-row' : 'flex layout-column', classes.embedModuleContainer)}
						>
							{
								bannerSubText
							&& (
								<SubLabelComponent
									classes={classes}
									label={bannerSubText}
									newStyle={bannerNewStyle}
								/>
							)
							}
							{
								is(Array, bannerTabs)
							&& (
								<Fragment>
									<div className="flex">
										<div className={classNames('flex layout-row', classes.embeddedModuleAndLinkContainer)}>
											<CustomTabs
												moduleId={moduleId}
												isMobileRes={isMobileRes}
											/>
											{!isMobileRes && giveawayLink
												&& (
													<div className={classNames(
														classes.tobiiBtnContainer,
														orNull(isNav, classes.navButton),
													)}
													>
														<LinkButton
															buttonType="tobiiButton"
															routeId={giveawayLink.routeId}
														>
															<span className={classes.tobiiLabel}>{giveawayLink.label}</span>
														</LinkButton>
													</div>
												)}
										</div>
										{embeddedContent?.withBanner && (
											<div className={classNames('flex layout-row justify-end my-2')}>
												<div className={classNames(classes.sort)}>
													<EmbeddedModule {...embeddedContent} />
												</div>
											</div>
										)}
									</div>
									<Divider className={classes.divider} />
								</Fragment>
							)
							}
							<div
								className={classNames(
									ternary(isNav,
										classes.embeddedModuleAndLinkContainerNav,
										classes.embeddedModuleAndLinkContainer),
								)}
								style={isNav ? { marginRight: -navMargin, marginLeft: -navMargin } : {}}
							>
								{orNull(
									createNewDareActive,
									<div className={classNames(
										classes.createLinkContainer,
										orNull(isNav, classes.navButton),
									)}
									>
										<LinkButton disabled={!projectsLoaded} routeId={linkRouteId}>
											<span className={classes.newDare}>{linkLabel}</span>
										</LinkButton>
									</div>,
								)}
								{orNull(embeddedContent && !embeddedContent.withBanner && !hideFilters,
									isMobileRes && filtersRoute
										? (
											<Link routeId={filtersRoute}>
												<div className={classes.iconWrapper}>
													<div className={classes.iconContainer}>
														<img className={classes.icon} src={filterIcon.src} alt="filters" width="24" height="24" />
													</div>
												</div>
											</Link>
										)
										: (
											<div className={classNames(
												classes.sort,
												orNull(isNav, classes.fullWidth),
											)}
											>
												<EmbeddedModule {...embeddedContent} />
											</div>
										))}
							</div>
						</div>
					</MaxWidthContainer>
				</div>
			</div>
		</>
	)
})

export default withModuleContext(
	bannerHeaderConnector(BannerHeaderUnconnected, styles, 'MuiBannerHeader'),
)
