/* eslint-disable max-len */

import React, { memo } from 'react'

import classNames from 'classnames'

import { fontFamily, ltGtMediaQuery } from 'root/src/client/web/commonStyles'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
	fontStyle: {
		fontFamily,
		fontSize: 32,
		fontWeight: 500,
		lineHeight: '38px',
		textTransform: 'inherit',
		textAlign: 'inherit',
		margin: 0,
		'@media(max-width: 1070px)': {
			fontSize: 26,
			lineHeight: '30px',
		},
		'@media(max-width: 1000px)': {
			fontSize: 22,
			lineHeight: '30px',
		},
	},
	fontSize: {
		width: '85%',
		lineHeight: 'unset',
		fontSize: 18,
		margin: [['auto', 0]],
	},
	notUpperCase: {
		textTransform: 'none',
	},
	light: {
		fontWeight: 400,
	},
	medium: {
		fontWeight: 500,
	},
	dareTitle: {
		wordBreak: 'break-word',
		[ltGtMediaQuery]: {
			width: '85%',
			margin: [['auto', 0]],
		},
	},
	dark: {
		color: ' #000',
	},
}

export const TitleUnstyled = memo((({ classes, children, notUpperCase, light, medium, fontSize, dareTitle, color }) => (
	<h2 className={classNames(classes.fontStyle, {
		[classes.notUpperCase]: notUpperCase,
		[classes.light]: light,
		[classes.medium]: medium,
		[classes.fontSize]: fontSize,
		[classes.dareTitle]: dareTitle,
		[classes.dark]: color === 'dark',
	})}
	>
		{children}
	</h2>
)))

export default withStyles(styles, { name: 'MuiTitle' })(TitleUnstyled)
