import reduxConnector from 'root/src/shared/util/reduxConnector'
import selectedTabSelectors from 'root/src/client/logic/tab/selectors/selectedTabSelector'
import setSelectedTabEndpointId from 'root/src/client/logic/tab/thunk/setSelectedTabEndpointId'
import tabsSelectors from 'root/src/client/logic/tab/selectors/tabsSelector'
import setSortFilterEmbedded from 'root/src/client/logic/embedded/thunks/setSortFilterEmbedded'
import isMobileResSelector from 'root/src/client/logic/app/selectors/isMobileResSelector'
import getFixedTabs from 'root/src/client/logic/tab/thunk/getFixedTabs'
import querySelector from 'root/src/client/logic/route/selectors/querySelector'
import dispatcher from 'root/src/client/logic/app/thunks/dispatcher'

export default reduxConnector(
	[
		['selectedTab', selectedTabSelectors],
		['tabs', tabsSelectors],
		['isMobileRes', isMobileResSelector],
		['query', querySelector],
	],
	[
		['setEndpoint', setSelectedTabEndpointId],
		['setInput', setSortFilterEmbedded],
		['getFixedTabs', getFixedTabs],
		['dispatch', dispatcher],
	],
)
