import { useEffect } from 'react';
export const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
        const listener = (event) => {
            const isClickOutside = (ref === null || ref === void 0 ? void 0 : ref.current) && !ref.current.contains(event.target);
            if (!isClickOutside || isIgnored(event)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
};
const CLASSES_TO_IGNORE = ['MuiBackdrop-root'];
function isIgnored(event) {
    if (!(event.target instanceof HTMLElement)) {
        return false;
    }
    return CLASSES_TO_IGNORE.some(className => event.target.classList.contains(className));
}
