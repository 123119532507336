import moduleDescriptions from 'root/src/shared/descriptions/modules'

import {
	bannerHeaderModuleDescriptionLenses,
} from 'root/src/client/logic/header/lenses'

const { viewEmbeddedContent } = bannerHeaderModuleDescriptionLenses

export default (state, { moduleId }) => {
	const embeddedContent = viewEmbeddedContent(
		moduleId, moduleDescriptions,
	)
	if (embeddedContent) {
		const { inputType } = embeddedContent.fields[0]
		return inputType === 'navEmbedded'
	}
	return undefined
}
