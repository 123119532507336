import {
	primaryGradient, secondaryGradient, ltGtMediaQuery,
	linkColor, linkGradient, errorColor, colorsNew,
	boxShadow,
} from 'root/src/client/web/commonStyles'
import { styledTwitchButton } from 'root/src/client/web/base/CustomButton/style'

export default {
	button: {
		display: 'flex',
		color: 'white',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		backgroundImage: primaryGradient,
		'&:hover': {
			backgroundImage: secondaryGradient,
		},
		width: '100%',
		height: '100%',
		textTransform: 'none',
		fontSize: 16,
		borderRadius: 4,
		fontWeight: 500,
		lineHeight: 1,
		'& > span': {
			position: 'absolute',
		},
		'& .button-subtitle': {
			fontSize: 12,
			fontWeight: 200,
		},
		[ltGtMediaQuery]: {
			fontSize: 15,
		},
	},
	buttonDisabled: {
		backgroundImage: 'none',
		backgroundColor: 'black',
		opacity: 0.4,
		'&:hover': {
			backgroundImage: 'none',
		},
	},
	buttonTest: {
		'& > span': {
			top: '50%',
			transform: 'translateY(-45%)',
		},
	},
	smallButton: {
		width: '30%',
		marginLeft: 5,
	},
	primaryButton: {
		padding: 10,
		fontSize: 18,
		textTransform: 'none',
		boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
	},
	noBackgroundButton: {
		padding: 10,
		fontSize: 18,
		textTransform: 'none',
		backgroundColor: 'transparent',
		backgroundImage: 'none',
		boxShadow: 'none',
		'&:hover': {
			backgroundImage: 'none',
			backgroundColor: 'transparent',
		},
	},
	outlinedButton: {
		color: 'transparent',
		backgroundColor: 'transparent',
		padding: 'auto',
		fontSize: 18,
		textTransform: 'none',
		boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
		'&:hover': {
			color: colorsNew.dark2,
			backgroundColor: 'transparent',
		},
	},
	secondaryButton: {
		...styledTwitchButton.button,
		width: '100%',
		height: '100%',
		'&:hover': {
			backgroundImage: 'none',
		},
	},
	squareButton: {
		borderRadius: 3,
		padding: 5,
		width: 'inherit',
		fontWeight: 400,
		marginLeft: 0,
	},
	loadMoreButton: {
		borderRadius: 0,
	},
	commentButton: {
		width: 96,
		height: 36,
		fontSize: 14,
		margin: 0,
	},
	outlined: {
		color: 'transparent',
		border: '1px solid white',
		backgroundColor: 'transparent',
		backgroundImage: 'none',
		width: '100%',
		'&:hover': {
			color: colorsNew.dark2,
			backgroundColor: 'transparent',
			backgroundImage: 'none',
		},
	},
	unstyled: {
		color: 'transparent',
		backgroundColor: 'transparent',
		boxShadow: 'none',
		'&:hover': {
			color: 'transparent',
			backgroundColor: 'transparent',
		},
	},
	tobii: {
		backgroundImage: linkGradient,
		'&:hover': {
			backgroundImage: linkColor,
		},
	},
	error: {
		color: `${errorColor} !important`,
		backgroundImage: 'none',
		border: `1px solid ${errorColor}`,
		boxShadow: 'none',
		'&:hover': {
			backgroundImage: 'none',
		},
		'& span': {
			color: errorColor,
		},
	},
	neonGlow: {
		padding: 2,
		borderRadius: 12,
		'&, & *': {
			cursor: 'pointer',
			transition: '0.3s',
		},
		border: '2px solid white',
		boxShadow: boxShadow(colorsNew.primary),
		'&:hover': {
			border: `2px solid ${colorsNew.primary}`,
			boxShadow: 'none',
			'& *': {
				color: colorsNew.primary,
			},
		},
		width: 100,
	},
}
