import path from 'ramda/src/path'
import pipe from 'ramda/src/pipe'
import { embeddedStoreLenses } from 'root/src/client/logic/embedded/lenses'

const { viewFieldData } = embeddedStoreLenses

export default (state, { moduleId }) => pipe(
	viewFieldData(moduleId),
	path(['tab', 'hideFilters']),
)(state)
