import {
	memo,
	useState,
	useMemo,
	useEffect,
	useRef,
} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import isNil from 'ramda/src/isNil'
import length from 'ramda/src/length'
import { camelCaseSpace } from 'root/src/shared/util/stringCase'
import arrow from 'root/src/client/public/icons/ic-dropdown.svg'
import onSelectHandler from 'root/src/client/logic/app/handlers/onSelectHandler'
import classNames from 'classnames'
import { colorsNew, tertiaryColor } from 'root/src/client/web/commonStyles'
import orEmpty from 'root/src/shared/util/orEmpty'
import { useOnClickOutside } from 'root/src/client/v2/common/hooks/useOnOutsideClick'

const selectStyle = {
	ddWrapper: {
		height: 40,
		minWidth: 190,
		width: '100%',
		borderRadius: 4,
		position: 'relative',
	},
	ddHeader: {
		cursor: 'pointer',
		display: 'flex',
		height: '100%',
		backgroundColor: colorsNew.dark2,
		position: 'relative',
		borderRadius: 4,
	},
	ddHeaderTitle: {
		display: 'flex',
		alignItems: 'center',
		color: tertiaryColor,
		paddingLeft: 10,
		height: '100%',
	},
	arrowUp: {
		transform: 'rotate(180deg) !important',
	},
	arrowDown: {
		width: 15,
		position: 'absolute',
		top: 12,
		right: 8,
		transitionDuration: '0.5s',
		cursor: 'pointer',
	},
	ddList: {
		position: 'absolute',
		left: 0,
		right: 0,
		zIndex: 99,
		padding: 0,
		margin: 0,
		border: tertiaryColor,
		borderStyle: 'solid',
		borderWidth: 2,
		borderTopStyle: 'none',
		maxHeight: 300,
		overflowY: 'auto',
	},
	ddListItem: {
		minHeight: 35,
		textIndent: -20,
		paddingLeft: 28,
		backgroundColor: colorsNew.dark2,
		listStyle: 'none',
		lineHeight: 2,
		cursor: 'pointer',
		boxSizing: 'border-box',
		marginRight: 0,
		'&:hover': {
			backgroundColor: tertiaryColor,
		},
	},
	veticalOverflow: {
		display: '-webkit-box',
		WebkitBoxOrient: 'vertical',
		WebkitLineClamp: 2,
		overflow: 'hidden',
		lineHeight: '22px',
	},
	searchableInput: {
		width: '100%',
		height: '100%',
		padding: [[0, 10]],
		backgroundColor: 'transparent',
		border: 'transparent',
		outline: 'none',
	},
	valueContainer: {
		width: '100%',
		position: 'relative',
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
	},
}

const getLabelMod = (value, options) => {
	if (typeof value === 'string') {
		return options.find(option => option.value === value)?.label
	}
	return value?.label
}

const Select = memo(({
	classes, value, onSelect, options, asyncOptions, placeholder, className, fieldTheme,
	hideDowndropArrow, locked, width, resettable, searchable,
}) => {
	const ref = useRef()
	const [search, setSearch] = useState('')
	const [listOpen, setListOpen] = useState(false)

	useOnClickOutside(ref, () => {
		if (listOpen) {
			setListOpen(false)
		}
	})

	useEffect(() => {
		// clear search when value is changed
		setSearch('')
	}, [value])

	const optionsMod = useMemo(() => {
		let items = Array.isArray(options) ? [...options] : []

		if (searchable) {
			items = options({ search })
		}

		if (resettable) {
			items.unshift({ label: '---', value: undefined })
		}

		return items
	}, [options, search])

	return (
		<div
			className={classNames(
				classes.ddWrapper,
				className,
			)}
			ref={ref}
			style={{
				backgroundColor: fieldTheme?.backgroundColor,
				...(width ? { width } : {}),
				...orEmpty(locked, { cursor: 'not-allowed' }),
			}}
		>
			<div
				className={classes.ddHeader}
				onClick={() => {
					if (!locked) {
						setListOpen(!listOpen)
					}
				}}
				style={{ backgroundColor: fieldTheme?.backgroundColor }}
			>
				<div
					className={classes.valueContainer}
					style={{
						color: fieldTheme?.placeholderColor,
						padding: searchable && listOpen ? 0 : 10,
					}}
				>
					{listOpen && searchable ? (
						<input
							onClick={(e) => {
								e.stopPropagation()
							}}
							onChange={e => setSearch(e.target.value)}
							value={search}
							placeholder="Search"
							className={classes.searchableInput}
						/>
					)
						: (
							<>{optionsMod.length > 0
								? !isNil(value)
									? (
										<span
											className={classes.veticalOverflow}
											style={{ color: fieldTheme?.textColor }}
										>
											{getLabelMod(value, optionsMod)}
										</span>
									)
									: placeholder
								: length(value) !== 0
									? (
										<span
											className={classes.veticalOverflow}
											style={{ color: fieldTheme?.textColor }}
										>
											{value.name || value.label}
										</span>
									)
									: placeholder
							} {value?.region && <>{camelCaseSpace(value.region)}</>}
							</>
						)}
				</div>
				{!(value && hideDowndropArrow) && (
					<img
						className={classNames(
							listOpen && classes.arrowUp,
							classes.arrowDown,
						)}
						style={locked ? { cursor: 'not-allowed' } : {}}
						src={arrow.src}
						alt="arrow-down"
					/>
				)}
			</div>
			{listOpen
				&& (
					<ul className={classes.ddList}>
						{optionsMod?.map(option => (
							// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
							<li
								key={option.value}
								value={option.value}
								className={classes.ddListItem}
								style={{ backgroundColor: fieldTheme?.dropdownOptionsColor }}
								onClick={onSelectHandler(option, onSelect, setListOpen)}
							>
								{option.label}
							</li>
						))}
						{asyncOptions && asyncOptions.map(option => (
							// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
							<li
								key={option.name || option.value}
								value={option.name || option.value}
								className={classes.ddListItem}
								style={{ backgroundColor: fieldTheme?.dropdownOptionsColor }}
								onClick={onSelectHandler(option, onSelect, setListOpen)}
							>
								{option.name || option.label} {option.region && (
									<span>({camelCaseSpace(option.region)})</span>
								)}
							</li>
						))}
					</ul>
				)}
		</div>
	)
})

export default withStyles(selectStyle, { name: 'MuiSelect' })(Select)
