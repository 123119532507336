import React, { memo } from 'react'

import classNames from 'classnames'

import { fontFamily } from 'root/src/client/web/commonStyles'

import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
	fontStyle: {
		fontFamily,
		fontSize: 22,
		lineHeight: 1.5,
		color: 'white',
		textAlign: 'center',
	},
}

export const HeaderUnstyled = memo((({ children, classes, className }) => (
	<div className={classNames(classes.fontStyle, className)}>
		{children}
	</div>
)))

export default withStyles(styles, { name: 'MuiHeader' })(HeaderUnstyled)
