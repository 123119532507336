import { isNilOrEmpty, mapP, pipeE } from 'root/src/shared/util/ramdaPlus'
import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'
import allFieldsValuesSelector
	from 'root/src/client/logic/embedded/selectors/allFieldsValuesSelector'
import fixedFilterSelector from 'root/src/client/logic/api/selectors/fixedFilterSelector'
import tabsFixedPayloadSelector from 'root/src/client/logic/tab/selectors/fixedPayloadSelector'
import getEndpointIdFromModules from 'root/src/client/logic/route/util/getEndpointIdFromModules'
import processFixedFilter from 'root/src/client/logic/sortFilter/util/processFixedFilter'
import currentRouteParamsRecordId
	from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'
import processSortFilter from 'root/src/client/util/processSortFilter'
import compose from 'ramda/src/compose'
import not from 'ramda/src/not'
import isEmpty from 'ramda/src/isEmpty'
import unnest from 'ramda/src/unnest'
import pageItemLengthSelector from 'root/src/client/logic/list/selectors/pageItemLengthSelector'
import tabsSelector from 'root/src/client/logic/tab/selectors/tabsSelector'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import listModuleIdSelector from 'root/src/client/logic/header/selectors/listModuleIdSelector'
import hideEmptyTabsSelector from 'root/src/client/logic/tab/selectors/hideEmptyTabsSelector'
import addIndex from 'ramda/src/addIndex'
import reject from 'ramda/src/reject'
import selectedTabSelectors from 'root/src/client/logic/tab/selectors/selectedTabSelector'
import pageItemsCountSelector from 'root/src/client/logic/list/selectors/pageItemsCountSelector'

export default moduleId => async (dispatch, getState) => {
	const state = getState()
	const listModuleId = listModuleIdSelector(state, { moduleId })
	const tabs = tabsSelector(state, { moduleId })
	const selectedTabId = selectedTabSelectors(state, { moduleId })

	if (!hideEmptyTabsSelector(state, { moduleId: listModuleId })) return tabs

	return pipeE(
		addIndex(mapP)(async ({
			endpointId,
			fixedPayload,
			...tab
		}, idx) => {
			const isSelectedTab = idx === selectedTabId
			const endpointIdMod = Array.isArray(endpointId)
				? endpointId[0]
				: endpointId
			const sortFilters = allFieldsValuesSelector(state, { moduleId })
			const fixedFilter = fixedFilterSelector({}, { moduleId })

			const tabsFixedPayload = tabsFixedPayloadSelector(sortFilters)
			const setEndpointId = (getEndpointIdFromModules(state, moduleId) || endpointIdMod)
			const startFilterValue = processFixedFilter(fixedFilter, currentRouteParamsRecordId(state))
			const pageItemLength = pageItemLengthSelector(state, { moduleId }) ?? pageItemsCountSelector(state, { moduleId: listModuleId })
			const requestPayload = processSortFilter({
				sortFilters: isSelectedTab ? sortFilters : {},
				startValue: {
					currentPage: 1,
					...(tabsFixedPayload || {}),
					...(compose(not, isEmpty, unnest)(startFilterValue) ? { filter: startFilterValue } : {}),
					sortType: undefined,
				},
				state,
			})

			const payloadMod = { ...requestPayload, ...fixedPayload, pageItemLength }
			// set default tab records
			const res = isSelectedTab
				? await dispatch(apiRequest(setEndpointId, payloadMod, false, listModuleId))
				: await invokeApiRequest(setEndpointId, payloadMod, state)

			const { items } = res?.body || {}

			if (!items?.length) {
				return undefined
			}

			return {
				...tab,
				endpointId,
				fixedPayload,
			}
		}),
		reject(isNilOrEmpty),
	)(tabs || [])
}
