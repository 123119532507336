import keys from 'ramda/src/keys'
import equals from 'ramda/src/equals'
import isEmpty from 'ramda/src/isEmpty'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import { CREATOR_PROJECTS_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

const { viewProject } = apiStoreLenses

export default (state, { moduleId }) => {
	if (equals(moduleId, CREATOR_PROJECTS_LIST_MODULE_ID)) {
		return !isEmpty(keys(viewProject(state)))
	}
	return true
}
