import reduxConnector from 'root/src/shared/util/reduxConnector'

import bannerImageSelector from 'root/src/client/logic/header/selectors/bannerImageSelector'
import bannerImageTextSelector from 'root/src/client/logic/header/selectors/bannerImageTextSelector'
import bannerSubTextSelector from 'root/src/client/logic/header/selectors/bannerSubTextSelector'
import bannerTabsSelector from 'root/src/client/logic/header/selectors/bannerTabsSelector'
import textWithBg from 'root/src/client/logic/header/selectors/textWithBg'
import createNewDareActive from 'root/src/client/logic/header/selectors/createNewDareActive'
import linkLabelSelector from 'root/src/client/logic/header/selectors/linkLabelSelector'
import linkRouteIdSelector from 'root/src/client/logic/header/selectors/linkRouteIdSelector'
import isMyProjectsSelector from 'root/src/client/logic/header/selectors/isMyProjectsSelector'
import embeddedContentSelector from 'root/src/client/logic/header/selectors/embeddedContentSelector'
import notifiedSelector from 'root/src/client/logic/header/selectors/notifiedSelector'
import bannerImageSubTextSelector from 'root/src/client/logic/header/selectors/bannerImageSubTextSelector'
import isMobileResSelector from 'root/src/client/logic/app/selectors/isMobileResSelector'
import filtersRouteSelector from 'root/src/client/logic/header/selectors/filtersRouteSelector'
import isNavSelector from 'root/src/client/logic/header/selectors/isNavSelector'
import projectsLoadedSelector from 'root/src/client/logic/header/selectors/projectsLoadedSelector'
import listModuleIdSelector from 'root/src/client/logic/header/selectors/listModuleIdSelector'
import giveawayLinkSelector from 'root/src/client/logic/header/selectors/giveawayLinkSelector'
import hideFiltersSelector from 'root/src/client/logic/header/selectors/hideFiltersSelector'
import bannerBoxImagesSelector from 'root/src/client/logic/header/selectors/bannerBoxImagesSelector'
import bannerNewStyleSelector from 'root/src/client/logic/header/selectors/bannerNewStyleSelector'
import oneRowSelector from 'root/src/client/logic/header/selectors/oneRowSelector'

export default reduxConnector(
	[
		['bannerImage', bannerImageSelector],
		['bannerImageText', bannerImageTextSelector],
		['bannerImageSubText', bannerImageSubTextSelector],
		['bannerSubText', bannerSubTextSelector],
		['bannerTabs', bannerTabsSelector],
		['textWithBg', textWithBg],
		['createNewDareActive', createNewDareActive],
		['linkLabel', linkLabelSelector],
		['linkRouteId', linkRouteIdSelector],
		['isMyProjects', isMyProjectsSelector],
		['embeddedContent', embeddedContentSelector],
		['notified', notifiedSelector],
		['isMobileRes', isMobileResSelector],
		['filtersRoute', filtersRouteSelector],
		['isNav', isNavSelector],
		['projectsLoaded', projectsLoadedSelector],
		['listModuleId', listModuleIdSelector],
		['giveawayLink', giveawayLinkSelector],
		['hideFilters', hideFiltersSelector],
		['bannerBoxImages', bannerBoxImagesSelector],
		['bannerNewStyle', bannerNewStyleSelector],
		['oneRow', oneRowSelector],
	],
	[],
)
