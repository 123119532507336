import { colorsNew } from 'root/src/client/web/commonStyles'

const defaultStyles = {
	width: '100%',
	outline: 'none',
	borderRadius: 4,
	background: 'none',
	border: '1px solid white',
	fontSize: 16,
	cursor: 'pointer',
	boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
	backgroundColor: colorsNew.dark2,
}

const styledTwitchButton = {
	link: {
		textDecoration: 'none',
	},
	button: {
		...defaultStyles,
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 5,
		color: colorsNew.dark2,
		lineHeight: 1,
		fontWeight: 500,
		height: 46,
		minWidth: '100%',
		'& .button-subtitle': {
			fontSize: 12,
			fontWeight: 200,
		},
		'& svg': {
			marginRight: 10,
			'@media (min-width: 960px) and (max-width: 988px)': {
				marginRight: 3,
			},
		},
		'@media(max-width: 1000px)': {
			padding: 3,
		},
	},
	buttonTitle: {
		'@media(max-width: 1000px)': {
			fontSize: 15,
		},
		'@media(max-width: 440px)': {
			fontSize: 12,
		},
	},
	buttonSubtitle: {
		fontSize: 11,
	},
}

const styledPledgeButton = {
	button: {
		...defaultStyles,
		fontWeight: 700,
		padding: 10,
		marginBottom: 20,
		backgroundColor: colorsNew.dark2,
	},
}

const styledSimpleButton = {
	button: {
		width: 136,
		height: 32,
		fontSize: 14,
		fontWeight: 400,
		borderRadius: 16,
		border: `1px solid ${colorsNew.dark2}`,
		outline: 'none',
		cursor: 'pointer',
		backgroundColor: '#fff',
		color: '#000',
	},
}

export { styledSimpleButton, styledTwitchButton, styledPledgeButton }
