import isNil from 'ramda/src/isNil'

export default (option, onSelectFn, setVisibleFn, isMulti, value, input) => () => {
	if (!isNil(option) && !isNil(onSelectFn)) {
		if (isMulti) {
			onSelectFn([...value, option])
		} else {
			onSelectFn(option)
		}
		if (!isNil(input)) {
			// eslint-disable-next-line no-param-reassign
			input.current.value = ''
			input.current.focus()
		}
	}

	if (!isNil(setVisibleFn)) {
		setVisibleFn(false)
	}
}
